.chart-error-component-container {
  position: absolute;
  z-index: 150;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  transition: background 0.25s ease;

  .chart-error-component-image {
    svg {
      width: 100px;
      height: 100px;
    }
  }
}
