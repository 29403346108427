.circle-loader-container {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--background-color);

  .circle-loader {
    position: relative;
    width: var(--size);
    height: var(--size);
    margin: 0 auto;
  }

  .circular {
    position: absolute;
    transform-origin: center center;
    width: 100%;
    height: 100%;
    margin: auto;
    animation: rotate var(--speed) linear infinite;
  }

  .path {
    stroke-dasharray: 120, 200;
    stroke-dashoffset: 35px;
    stroke-linecap: round;
    stroke-width: 3;
    animation: dash 1.7s cubic-bezier(0.36, 0, 0.37, 0.99) 1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 120, 200;
    stroke-dashoffset: 35px;
  }
}
