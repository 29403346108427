.dots-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  .dots-loader {
    position: relative;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    animation: flash var(--speed) ease-out infinite alternate;
  }
}

@keyframes flash {
  0% {
    background-color: var(--box-shadow-color);
    box-shadow: calc(var(--size) * 1.5) 0 var(--box-shadow-color), calc(var(--size) * -1.5) 0 var(--background-color);
  }

  50% {
    background-color: var(--background-color);
    box-shadow: calc(var(--size) * 1.5) 0 var(--box-shadow-color), calc(var(--size) * -1.5) 0 var(--box-shadow-color);
  }

  100% {
    background-color: var(--box-shadow-color);
    box-shadow: calc(var(--size) * 1.5) 0 var(--background-color), calc(var(--size) * -1.5) 0 var(--box-shadow-color);
  }
}
