.chart-button-tooltip-container {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;

  .chart-button-tooltip {
    position: absolute;
    top: calc(var(--top) + var(--button-height));
    left: calc(var(--left) - calc(var(--tooltip-width) / 2) + calc(var(--button-width) / 2));
    transform: scale(0);
    margin: 5px 0 0;
    padding: 4px 8px;
    font-size: 11px;
    color: #031424;
    white-space: nowrap;
    opacity: 0;
    background: #EBEBEB;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;
    transition:
      transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background 0.25s ease,
      color 0.25s ease;

    &.show {
      transform: scale(1);
      opacity: 1;
    }

    &.right-side {
      top: calc(var(--top) + calc(var(--button-height) / 2) - calc(var(--tooltip-height) / 2));
      left: calc(var(--left) + var(--button-width));
      margin: 0 0 0 5px;
    }
  }
}

.chart-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .chart-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 5px;
    font-size: 14px;
    color: #031424;
    background: #EBEBEB;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: background 0.25s ease, color 0.25s ease;

    &:hover {
      background: #DDD;
    }

    &:active {
      background: #D3D3D3;
    }

    * {
      pointer-events: none;
    }
  }
}
