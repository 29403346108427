.chart-dropdown-container {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;

  &.auto {
    .chart-select-dropdown {
      width: auto;
    }
  }

  &.wrap {
    .chart-select-dropdown {
      .chart-select-list {
        flex-flow: wrap;
      }
    }
  }

  &.right-side {
    .chart-select-dropdown {
      top: var(--top);
      left: calc(var(--left) + var(--width));
      width: auto;
      margin: 0 0 0 5px;
    }
  }

  .chart-select-dropdown {
    position: absolute;
    z-index: 100;
    top: calc(var(--top) + var(--height));
    left: var(--left);
    overflow: hidden;
    display: none;
    width: var(--width);
    margin: 5px 0 0;
    background: #EBEBEB;
    border-radius: 0.25rem;
    transition: background 0.25s ease;

    &.open {
      display: block;
    }

    .chart-select-list {
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 155px;
      margin: 0;
      padding: 0;
      list-style: none;

      .chart-select-list-item {
        cursor: pointer;
        display: flex;
        column-gap: 5px;
        align-items: center;
        padding: 5px;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          background: #DDD;
        }

        &.active {
          color: #379BE7;
        }

        svg {
          width: 25px;
          height: 25px;
        }

        * {
          pointer-events: none;
          transition: transform 0.25s ease;
        }
      }
    }
  }
}

.chart-select-container {
  position: relative;

  &.right-side {
    .chart-select {
      &:hover {
        .chart-select-button {
          opacity: 1;
        }
      }

      &.open {
        .chart-select-button {
          svg {
            transform: rotate(90deg);
          }
        }
      }

      .chart-select-label {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .chart-select-button {
        position: absolute;
        right: -9px;
        padding: 0;
        opacity: 0;

        svg {
          transform: rotate(270deg);
        }
      }
    }
  }

  .chart-select {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #031424;
    background: #EBEBEB;
    border-radius: 0.25rem;
    transition: background 0.25s ease, color 0.25s ease;

    &.open {
      .chart-select-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .chart-select-label {
      user-select: none;
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 5px;
      font-size: 14px;
      line-height: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.active {
        color: #379BE7;
      }
    }

    .chart-select-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 5px 10px;
      font-size: 14px;
      background: transparent;
      border: none;
      border-radius: 0.25rem;
      outline: none;
      transition: opacity 0.25s ease;

      svg {
        transition: transform 0.25s ease;
      }
    }
  }
}
