.chart-preset-bar {
  &.vertical {
    .chart-toolbar {
      .chart-toolbar-content {
        overflow-x: hidden;
        overflow-y: auto;
        flex-direction: column;
      }
    }
  }

  &.horizontal {
    .chart-toolbar {
      .chart-toolbar-content {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
  }

  .chart-toolbar {
    overflow: hidden;
    display: flex;

    &.hide {
      display: none;
    }

    .chart-toolbar-content {
      display: flex;
      row-gap: 10px;
      column-gap: 10px;
      padding: 5px;

      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-corner {
        display: none;
      }

      .chart-text {
        line-height: 30px;
        white-space: nowrap;
      }
    }
  }
}
