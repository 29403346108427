.fibonacci-modal-content {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  grid-template-areas:
    'fib-trend-visible                 fib-trend-color               fib-trend-width                 fib-trend-style'
    'fib-lines-level-text              fib-lines-level-text          fib-lines-level-width           fib-lines-level-style'
    'fib-lines-extend-left             fib-lines-extend-left         fib-lines-extend-left           fib-lines-extend-left'
    'fib-lines-extend-right            fib-lines-extend-right        fib-lines-extend-right          fib-lines-extend-right'
    'fib-background-visible            fib-background-visible        fib-background-opacity          fib-background-opacity'
    'fib-levels-content                fib-levels-content            fib-levels-content              fib-levels-content';
  align-items: center;

  &.channel_fibonacci {
    grid-template-areas:
      'fib-lines-level-text              fib-lines-level-text          fib-lines-level-width           fib-lines-level-style'
      'fib-lines-extend-left             fib-lines-extend-left         fib-lines-extend-left           fib-lines-extend-left'
      'fib-lines-extend-right            fib-lines-extend-right        fib-lines-extend-right          fib-lines-extend-right'
      'fib-background-visible            fib-background-visible        fib-background-opacity          fib-background-opacity'
      'fib-levels-content                fib-levels-content            fib-levels-content              fib-levels-content';
  }

  .fibonacci-trend-visible { grid-area: fib-trend-visible; }
  .fibonacci-trend-color { grid-area: fib-trend-color; }
  .fibonacci-trend-width { grid-area: fib-trend-width; }
  .fibonacci-trend-style { grid-area: fib-trend-style; }
  .fibonacci-lines-level-text { grid-area: fib-lines-level-text; }
  .fibonacci-lines-level-width { grid-area: fib-lines-level-width; }
  .fibonacci-lines-level-style { grid-area: fib-lines-level-style; }
  .fibonacci-lines-extend-left { grid-area: fib-lines-extend-left; }
  .fibonacci-lines-extend-right { grid-area: fib-lines-extend-right; }
  .fibonacci-background-visible { grid-area: fib-background-visible; }
  .fibonacci-background-opacity { grid-area: fib-background-opacity; }

  .chart-fibonacci-level-content {
    display: grid;
    grid-area: fib-levels-content;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas:
      'fibonacci-level-visible_0  fibonacci-level-percent_0  fibonacci-level-color_0          fibonacci-level-visible_1  fibonacci-level-percent_1  fibonacci-level-color_1'
      'fibonacci-level-visible_2  fibonacci-level-percent_2  fibonacci-level-color_2          fibonacci-level-visible_3  fibonacci-level-percent_3  fibonacci-level-color_3'
      'fibonacci-level-visible_4  fibonacci-level-percent_4  fibonacci-level-color_4          fibonacci-level-visible_5  fibonacci-level-percent_5  fibonacci-level-color_5'
      'fibonacci-level-visible_6  fibonacci-level-percent_6  fibonacci-level-color_6          fibonacci-level-visible_7  fibonacci-level-percent_7  fibonacci-level-color_7';
  }
}
