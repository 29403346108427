.chart-toolbar-modal-content {
  display: flex;
  align-items: center;

  .chart-toolbar-modal-drag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    background: transparent;
    border: none;
  }

  .chart-toolbar-modal-drag-icon {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  .chart-toolbar-modal-body {
    display: flex;
    align-items: center;
    padding: 2px 5px 2px 0;
  }
}
