.chart-dropdown-dropdown-container {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;

  &.auto {
    .chart-dropdown {
      width: auto;
    }
  }

  &.right-side {
    .chart-dropdown {
      top: var(--top);
      left: calc(var(--left) + var(--width));
      width: auto;
      margin: 0 0 0 5px;
    }
  }

  .chart-dropdown {
    position: absolute;
    z-index: 100;
    top: calc(var(--top) + var(--height));
    left: var(--left);
    overflow: hidden;
    display: none;
    width: var(--width);
    margin: 5px 0 0;
    padding: 5px;
    background: #EBEBEB;
    border-radius: 0.25rem;
    transition: background 0.25s ease;

    &.open {
      display: block;
    }
  }
}

.chart-dropdown-trigger-container {
  position: relative;

  &.right-side {
    .chart-dropdown-trigger {
      &:hover {
        .chart-dropdown-trigger-button {
          opacity: 1;
        }
      }

      &.open {
        .chart-dropdown-trigger-button {
          svg {
            transform: rotate(90deg);
          }
        }
      }

      .chart-dropdown-trigger-label {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .chart-dropdown-trigger-button {
        position: absolute;
        right: -9px;
        padding: 0;
        opacity: 0;

        svg {
          transform: rotate(270deg);
        }
      }
    }
  }

  .chart-dropdown-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #031424;
    background: #EBEBEB;
    border-radius: 0.25rem;
    transition: background 0.25s ease, color 0.25s ease;

    &.open {
      .chart-dropdown-trigger-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .chart-dropdown-trigger-label {
      user-select: none;
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 5px;
      font-size: 14px;
      line-height: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .chart-dropdown-trigger-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 5px 10px;
      font-size: 14px;
      background: transparent;
      border: none;
      border-radius: 0.25rem;
      outline: none;
      transition: opacity 0.25s ease;

      svg {
        transition: transform 0.25s ease;
      }
    }
  }
}
