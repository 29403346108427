.chart-preset-container,
.chart-preset-item,
.chart-preset-chart,
.chart-preset-axis {
  position: relative;
}

.chart-preset-container,
.chart-preset-item,
.chart-preset-chart {
  width: 100%;
  height: 100%;
}

.chart-preset-container,
.chart-preset-item {
  display: grid;
}

.chart-preset-bar,
.chart-preset-profit {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.chart-preset-profit {
  column-gap: 5px;
  padding: 5px;
}

.chart-preset-bar {
  &.vertical {
    z-index: 99;
    flex-direction: column;
  }
}

.left-side {
  grid-template-columns: 3fr min(25%, 500px);
}

.right-side {
  grid-template-columns: min(25%, 500px) 3fr;
}
