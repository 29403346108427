.chart-modal-drag-container {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;

  .chart-modal {
    position: absolute;
    z-index: 101;
    visibility: hidden;
    background: #FAFAFA;
    border-radius: 8px;
    transition: background 0.25s ease;

    &.show {
      visibility: visible;
    }
  }

  .chart-modal-drag-element {
    cursor: grab;
    display: flex;
    align-items: center;

    &.grabbing {
      cursor: grabbing;
    }
  }
}
