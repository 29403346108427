.measure-tool-dropdown {
  &.chart-dropdown-trigger-container {
    .chart-dropdown-trigger {
      .chart-dropdown-trigger-label {
        height: 30px;
      }
    }
  }
}

.measure-tool-dropdown-content {
  display: grid;
  grid-row-gap: 10px;
  align-items: center;
  padding: 5px;

  .measure-tool-interval.chart-input-container-text {
    .chart-input-text {
      padding: 4px;
    }
  }
}
