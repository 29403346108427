.background-image-modal {
  .background-image-modal-content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .background-image-file {
      &.chart-input-container {
        .chart-input-label {
          min-width: 320px;
          text-align: center;
        }
      }
    }

    .background-image-opacity {
      &.chart-input-container {
        flex-direction: row-reverse;
        column-gap: 15px;
      }
    }
  }

  .chart-content-modal-footer {
    flex-direction: column;
    row-gap: 10px;
  }
}
