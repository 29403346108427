.parallel-channel-modal-content {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  grid-template-areas:
    'channel-text                 channel-color               channel-width                 channel-style'
    'channel-extend-left          channel-extend-left         channel-extend-left           channel-extend-left'
    'channel-extend-right         channel-extend-right        channel-extend-right          channel-extend-right'
    'channel-line-visible         channel-line-color          channel-line-width            channel-line-style'
    'channel-background-visible   channel-background-color    channel-background-opacity    channel-background-opacity';
  align-items: center;

  .parallel-channel-text { grid-area: channel-text; }
  .parallel-channel-color { grid-area: channel-color; }
  .parallel-channel-width { grid-area: channel-width; }
  .parallel-channel-style { grid-area: channel-style; }
  .parallel-channel-line-visible { grid-area: channel-line-visible; }
  .parallel-channel-line-color { grid-area: channel-line-color; }
  .parallel-channel-line-width { grid-area: channel-line-width; }
  .parallel-channel-line-style { grid-area: channel-line-style; }
  .parallel-channel-extend-left { grid-area: channel-extend-left; }
  .parallel-channel-extend-right { grid-area: channel-extend-right; }
  .parallel-channel-background-visible { grid-area: channel-background-visible; }
  .parallel-channel-background-color { grid-area: channel-background-color; }
  .parallel-channel-background-opacity { grid-area: channel-background-opacity; }
}
