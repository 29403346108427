.chart-content-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.scroll {
    .chart-content-modal-body {
      overflow: hidden;

      .chart-content-modal-scrollbar {
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .chart-content-modal-header {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #EBEBEB;
    transition: border 0.25s ease;

    .chart-content-modal-title {
      * {
        font-size: 16px;
      }
    }

    .chart-content-modal-close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      padding: 0;
      background: transparent;
      border: none;
    }
  }

  .chart-content-modal-body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding: 0 15px;

    .chart-content-modal-scrollbar {
      padding: 10px 0;
    }
  }

  .chart-content-modal-footer {
    display: flex;
    flex: 0 0 auto;
    padding: 15px;
  }
}
